import React from 'react'
import { Flex, Box } from 'reflexbox'
import Button from './Button'

const Banner = ({ children }) => (
  <Box py={[3, 4]} fontSize={[3, 4]}>
    <Flex
      flexDirection={['column', 'column', 'column', 'row']}
      justifyContent="space-between"
    >
      <Box maxWidth="600px">{children}</Box>
      <Box>
        <Flex
          height="100%"
          flexDirection={['column', 'row', 'row', 'column']}
          justifyContent="center"
          mb="5px"
          mt={[2, 3, 3, 0]}
        >
          <Button
            mb={[3, 0, 0, 4]}
            mr={[0, 4, 4, 0]}
            width={['100%', '50%', '50%', '300px']}
            to="/custom"
          >
            Custom Made
          </Button>
          <Button width={['100%', '50%', '50%', '300px']} to="/ready">
            Ready to ship
          </Button>
        </Flex>
      </Box>
    </Flex>
  </Box>
)

export default Banner
