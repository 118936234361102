import React from 'react'
import { graphql } from 'gatsby'
import ProductGrid from '~/components/ProductGrid'
import Container from '../components/ui/Container'
import Banner from '../components/ui/Banner'
import SEO from '../components/seo'

const IndexPage = ({ data }) => {
  const shelves = data.allShopifyCollection.edges
  return (
    <>
      <SEO
        isHome
        title="Prospect Leather | Handmade leather goods & accessories"
        keywords={[
          `leather`,
          `leathercraft`,
          `edc`,
          `leather wallets`,
          `wallet`,
          `accessories`,
        ]}
      />
      <Container>
        <Banner>
          <p>
            Prospect Leather is a one-man shop for hand-crafted leather goods.
          </p>
          <p>
            Most of my work is commission-based, but I also sell a select few
            items for general sale.
          </p>
        </Banner>
        {shelves.map(({ node: shelf }) => {
          if (shelf.products.length <= 0) return null
          const products = shelf.products.filter(
            product => product.availableForSale
          )
          const shouldShowSoldProducts = products.length < 3
          const isBespoke = shelf.handle === 'bespoke'
          return (
            <ProductGrid
              handle={shelf.handle}
              heading={shelf.title}
              isBespoke={isBespoke}
              description={shelf.description}
              products={shouldShowSoldProducts ? shelf.products : products}
              key={shelf.id}
            />
          )
        })}
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    allShopifyCollection(sort: { fields: id, order: ASC }) {
      edges {
        node {
          handle
          title
          id
          description
          products {
            availableForSale
            id
            handle
            title
            productType
            variants {
              price
            }
            images {
              id
              originalSrc
              localFile {
                childImageSharp {
                  fluid(maxWidth: 910, maxHeight: 600) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
